<template>
  <div class="customer-list">
    <pagoda-form-table-layout>
      <!-- 表单 -->
      <template slot="form" slot-scope="scope">
        <el-form class="el-form-reset" inline>
          <el-form-item label="客户类型" prop="customer_type">
            <el-select
              v-model="formData.customer_type"
              placeholder="请选择客户类型"
            >
              <el-option
                v-for="item in customerTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户状态" prop="customer_status">
            <el-select
              v-model="formData.customer_status"
              placeholder="请选择客户状态"
            >
              <el-option
                v-for="item in customerStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业标签" prop="enterprise_label">
            <el-cascader
              clearable
              filterable
              ref="cascader"
              placeholder="请选择企业标签"
              v-model="enterprise_label"
              :options="companyTags"
              :props="cascaderProps"
              @change="handleChangeTag"
              @expand-change="expandChange"
              :collapse-tags="isCollapseTags"
            ></el-cascader>
            <span class="cascader-tips">最多选择一次全选200个</span>
          </el-form-item>
          <el-form-item label="跟进成员" prop="members">
            <member-transfer
              title="成员"
              ref="memberTransfer"
              @selectedList="addSelectedList"
            ></member-transfer>
          </el-form-item>
          <el-form-item label="客户名称" prop="keyword">
            <el-input
              placeholder="请输入客户名称"
              v-model="formData.keyword"
            ></el-input>
          </el-form-item>
          <el-form-item label="添加时间" prop="addDate">
            <el-date-picker
              v-model="formData.addDate"
              type="daterange"
              @change="handleChangeDate"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
          <!-- 表单操作按钮 -->
          <div class="pagoda-button-group" v-bind="scope.btnPosition">
            <el-button @click="handleReset">重置</el-button>
            <el-button type="primary" @click="handleSubmit">查询</el-button>
          </div>
        </el-form>
        <div class="total-number">
          当前有{{ totalDataNum }}个客户人次，共{{ customerNum }}个客户
        </div>
      </template>
      <!-- 实现一屏展示时 一定要配置表格高度 -->
      <template slot="table" slot-scope="scope">
        <el-table
          ref="table"
          :data="tableData"
          :height="scope.height"
          v-loading="isTableLoading"
          element-loading-background="rgba(0, 0, 0, 0)"
        >
          <el-table-column
            prop="customerType"
            min-width="200"
            label="客户名称/类型"
          >
            <template slot-scope="scope">
              <div class="customer-type">
                <el-image :src="scope.row.img"></el-image>
                <div>
                  {{ scope.row.name }}
                  <span
                    class="name-croWechat"
                    v-if="scope.row.customer_type === '2'"
                  >
                    @{{ scope.row.corp_name }}
                  </span>
                  <span v-else>@微信</span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="member_name"
            width="150"
            label="跟进成员"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            width="150"
            label="添加时间"
          ></el-table-column>
          <el-table-column prop="label" min-width="150" label="企业标签">
            <template slot-scope="scope">
              <div
                class="company-tag"
                v-for="(item, index) in scope.row.label"
                :key="index"
              >
                {{ item }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="status"
            width="150"
            label="状态"
          ></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row.id)">
                详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <!-- 分页 -->
      <template slot="pagination">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :current-page="pageNumber"
          :total="totalDataNum"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </template>
    </pagoda-form-table-layout>
  </div>
</template>
<script>
import memberTransfer from '@/components/member-transfer'
import http from '@/services/api/customer'
export default {
  data() {
    return {
      formData: {
        page: 1,
        page_size: 10
      },
      enterprise_label: [],
      tableData: [],
      customerTypeList: [],
      customerStatusList: [],
      companyTags: [],
      totalDataNum: 0,
      customerNum: 0,
      pageSize: 10,
      pageNumber: 1,
      isTableLoading: false,
      collapseNum: 10,
      limitLength: 200,
      timer: null,
      currentIndex: undefined, // 当前滚动区域所属一级id;
      cascaderProps: {
        multiple: true
        // checkStrictly:true
      }
    }
  },
  computed: {
    isCollapseTags() {
      let length = this.enterprise_label.length || 0
      return length > this.collapseNum ? true : false
    }
  },
  components: {
    memberTransfer
  },
  methods: {
    expandChange(valueList) {
      let companyTags = this.companyTags
      this.currentIndex = companyTags.findIndex((element) => {
        return element.value === valueList[0]
      })
      // 在展开时,绑定滚动事件;
      this.$nextTick(() => {
        this.secondElementScroll()
      })
    },
    secondElementScroll() {
      let _this = this
      const Cascader_DOM = document.getElementsByClassName(
        'el-cascader-menu'
      )[1]
      console.log('Cascader_DOM', Cascader_DOM)
      if (Cascader_DOM) {
        const Cascader_DOM_warp = Cascader_DOM.getElementsByClassName(
          'el-cascader-menu__wrap'
        )[0]
        // 绑定滚动事件;
        Cascader_DOM_warp.addEventListener('scroll', function (event) {
          let { scrollTop, scrollHeight } = event.target
          let offsetHeight = Math.ceil(
            event.target.getBoundingClientRect().height
          )
          let currentHeight = scrollTop + offsetHeight
          if (currentHeight >= scrollHeight) {
            // 节流;
            if (this.timer) {
              clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
              console.log('触底啦')
              _this.loadMore(Cascader_DOM_warp, scrollTop)
            }, 50)
          }
        })
      }
    },
    // 加载更多;
    loadMore(dom, lastScrollPosition) {
      console.log('currentIndex', this.currentIndex)
      let { companyTags, currentIndex } = this
      let { range, group_list, children } = this.companyTags[currentIndex]
      let totalLength = group_list.length
      if (range < totalLength) {
        let nextRange = parseInt(range) + this.limitLength
        let addChildren = group_list.slice(range, nextRange)
        this.companyTags[currentIndex].range = nextRange
        this.companyTags[currentIndex].children = [...children, ...addChildren]
        this.$nextTick(() => {
          if (dom && lastScrollPosition) {
            console.log('lastScrollPosition', lastScrollPosition)
            setTimeout(() => {
              dom.scrollTo(0, lastScrollPosition)
            }, 0)
          }
        })
      }
    },
    handleSubmit() {
      this.formData.page = 1
      this.formData.page_size = 10
      this.pageNumber = 1
      this.pageSize = 10
      this.getList()
    },
    getList() {
      const params = this.formData
      http
        .getCustomerList(params)
        .then((res) => {
          this.tableData = res.data.list || []
          this.totalDataNum = res.data.total_num || 0
        })
        .catch(() => {})
      http
        .getCustomerNum(params)
        .then((res) => {
          this.customerNum = res.data.total_customer_num || 0
        })
        .catch(() => {})
    },
    handleReset() {
      this.enterprise_label = []
      this.$refs.memberTransfer.selectedList = []
      this.$refs.memberTransfer.tableData = []
      this.$refs.memberTransfer.formData.keyword = ''
      this.formData = {
        page: 1,
        page_size: 10
      }
      this.pageNumber = 1
      this.pageSize = 10
      this.getList()
    },
    // pageSize 改变时会触发
    handleSizeChange(val) {
      this.pageSize = val
      this.formData.page_size = val
      this.refreshTableData()
    },
    // currentPage 改变时会触发
    handleCurrentChange(val) {
      this.pageNumber = val
      this.formData.page = val
      this.getList()
    },
    // 刷新表格数据，重置回第一页
    refreshTableData() {
      this.pageNumber = 1
      this.formData.page = 1
      this.getList()
    },
    // 至详情页
    handleDetail(id) {
      this.$router.push({
        path: '/customerManageDetail',
        query: {
          id
        }
      })
    },
    // 获取客户类型
    getCustomerType() {
      http
        .getCustomerType()
        .then((res) => {
          this.customerTypeList = res.data
        })
        .catch(() => {})
    },
    // 获取客户状态
    getCustomerStatus() {
      http
        .getCustomerStatus()
        .then((res) => {
          this.customerStatusList = res.data
        })
        .catch(() => {})
    },
    // 获取企业标签
    getCompanyTag() {
      http
        .getCompanyTag()
        .then((res) => {
          const data = res.data
          data.forEach((item) => {
            item.label = item.group_name
            item.value = item.group_name
            item.range = 200
            item.group_list.forEach((item) => {
              item.label = item.label_name
              item.value = item.id
            })
            // item.children = item.group_list
            item.children = item.group_list.slice(0, 200)
            this.companyTags.push(item)
          })
        })
        .catch(() => {})
    },
    handleChangeTag(arr) {
      console.log('handleChangeTag--handleChangeTag', arr)
      this.formData.enterprise_label = []
      arr.forEach((item) => {
        let length = item.length
        this.formData.enterprise_label.push(item[length - 1])
      })
    },
    handleChangeDate(arr = []) {
      this.formData.start_time = arr[0]
      this.formData.end_time = arr[1]
    },
    // 搜索添加成员
    addSelectedList(selectedList) {
      this.formData.members = selectedList
    }
  },
  created() {
    this.getCustomerType()
    this.getCustomerStatus()
    this.getCompanyTag()
    this.handleSubmit()
  },
  activated() {
    // 刷新列表数据
    this.refreshListData()
    this.$nextTick(() => {
      // 切换页面后表格偏移问题，对table进行重新布局
      this.$refs['table'].doLayout()
    })
  }
}
</script>

<style lang="less">
.customer-list {
  height: 100%;
  .el-date-editor--monthrange.el-input,
  .el-date-editor--monthrange.el-input__inner {
    width: 220px;
  }
}
.customer-type {
  display: flex;
  min-height: 40px;
  line-height: 40px;
  padding-left: 45px;
  position: relative;
  .el-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.total-number {
  margin-top: 10px;
  padding: 10px 0 20px;
  border-top: 1px solid #e1e6eb;
}
.company-tag {
  display: inline-block;
  background-color: #00c587;
  color: #fff;
  border-radius: 3px;
  padding: 0 5px;
  margin: 3px;
}
.customer-type span {
  color: #07c160;
}
.customer-type .name-croWechat {
  color: #e98839;
}
.el-dropdown-menu.pagoda-layout-user-info .user-name {
  text-align: left;
}

.cascader-tips {
  color: #c0c4cc;
}
</style>
